<template>
  <div class="container">
    <Header />
    <div class="main">
      <div class="reservation">
        <div>
          <span>{{ $t('hotelName') }}: </span>
          <span>{{ reservation.hotel && reservation.hotel.name || '' }}</span>
        </div>
        <div>
          <span>{{ $t('bookingNumber') }}: </span>
          <span>{{ reservation.booking_number }}</span>
        </div>
        <el-row style="display: flex; flex-direction: row; align-items: center;">
          <el-col :span="2">
            <span>{{ $t('finalPrice') }}: </span>
          </el-col>
          <el-col :span="10" class="rate">
            <span>{{ reservation.currency_code || '' }}</span>
            <span>{{ reservation.total_amount | formatNumber }}</span>
          </el-col>
        </el-row>
        <el-row style="display: flex; flex-direction: row; align-items: center;">
          <el-col :span="2">
            <span>{{ $t('due') }}: </span>
          </el-col>
          <el-col :span="10" style="color: #f3ab0f;">
            <span>{{ reservation.currency_code || '' }}</span>
            <span>{{ reservation.total_amount | formatNumber }}</span>
          </el-col>
        </el-row>

        <div>{{ $t('details') }}:</div>

        <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
          <div
            v-for="(item, index) of details"
            :key="index"
          >
            <div class="table-cell">{{ item.reservation_date }}</div>
            <div class="table-cell" style="margin-top: -1px;">{{ item.rate_amount | formatNumber }}</div>
          </div>
        </div>

        <div style="color: #333;">{{ $t('paymentMethod') }} <span style="color: red;">({{ $t('payment notice') }})</span></div>

        <el-row class="payment-type" style="padding: 0;">
          <div class="title">
            {{ $t('payType') }}
          </div>

          <el-radio-group v-model="reservation.payment_method" class="el-row el-row--flex" style="padding: 30px 20px 30px 20px;">
            <el-col
              v-for="item of paymentMethods"
              :key="item.id"
              :span="6"
            >
              <el-radio
                :label="item.code"
                style="display: flex; flex-direction: row; align-items: center;"
              >
                <img :src="'/server-static/' + item.icon" />
              </el-radio>
            </el-col>
          </el-radio-group>
        </el-row>
      </div>

      <div style="margin-top: 30px; display: flex; flex-direction: row; justify-content: flex-end; align-items: center;">
        <span>{{ $t('availableTime') }}: </span>
        <span class="cu-count-down">{{ day }}</span>
        <span>{{ $t('day') }}</span>
        <span class="cu-count-down">{{ hour }}</span>
        <span>{{ $t('hour') }}</span>
        <span class="cu-count-down">{{ minute }}</span>
        <span>{{ $t('minute') }}</span>
        <span class="cu-count-down">{{ second }}</span>
        <span style="padding-right: 8px;">{{ $t('second') }}</span>
        <el-button type="primary" size="large" @click="pay">{{ $t('payNow') }}</el-button>
      </div>

      <div style="flex: 1;"></div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import get from 'get-value'
import { Notify } from 'vant'
import Header from '@/components/Header'
import { formatNumber } from '@/filters'
import { getDates, countDown } from '@/utils'
import dayjs from '@/utils/dayjs'
import CountDown from '@/mixins/count-down'

export default {
  name: 'Payment',
  components: {
    Header
  },
  mixins: [CountDown],
  data () {
    return {
      activeName: '',
      paymentMethods: [],
      showDialog: false,
      reservation: {
        arrival: '',
        departure: '',
        total_amount: '',
        currency_code: '',
        payment_method: '',
        last_pay_time: '',
        roomType: {
          name: '',
          image: ''
        },
        hotel: {
          name: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'language'
    ]),
    details () {
      const dates = getDates(this.reservation.arrival, this.reservation.departure)
      if (dates.length > 1) {
        dates.pop()
      }
      return dates.map(date => ({
        reservation_date: date,
        rate_amount: this.reservation.rate_amount
      }))
    },
    time () {
      return dayjs(this.reservation.last_pay_time).diff(dayjs())
    }
  },
  async mounted () {
    try {
      const response = await this.$axios.get('/payment-method', {
        params: {
          search: 'enable==1',
          sort: 'sequence'
        }
      })
      this.paymentMethods = response.data.rows

      const res = await this.$axios.get(`/reservation/${this.$route.params.reservationId}`)
      if (res.data) {
        this.reservation = res.data
        const reservation = this.reservation

        if (!reservation.payment_method === 'NotSelected') {
          this.$router.push({
            name: reservation.payment_method,
            params: { reservationId: reservation.id }
          })
        }
      }
    } catch (e) {
      Notify(e.message)
    }
    const _this = this
    setInterval(() => {
      countDown.bind(_this)(_this.reservation.last_pay_time)
    }, 200)
  },
  methods: {
    get,
    formatNumber,
    getContainer () {
      return document.querySelector('.main')
    },
    async pay () {
      if (this.reservation.payment_method === 'NotSelected') {
        Notify(this.$t('payment method not selected'))
        return
      }

      const res = await this.$axios.patch(`/reservation/${this.reservation.id}/payment-method/${this.reservation.payment_method}`)
      if (res && res.errCode === 0) {
        this.$router.push({
          name: 'OfflinePayment',
          params: { reservationId: this.$route.params.reservationId }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  max-width: 1200px;
  margin: 0 auto;
}

.reservation {
  margin-top: 30px;
  & > div {
    padding: 10px 0;
    color: #515a6e;
  }
}

.table-cell {
  border: 1px solid #dedede;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: -1px;
}

.payment-type {
  border: 1px solid #dedede;

  .title {
    padding: 10px 10px 10px 20px;
    background-color: #F9f9f9;
  }
}
</style>
